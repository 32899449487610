// App.js
import React, { Suspense } from 'react';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import { CSpinner } from '@coreui/react';
import './scss/style.scss';
import './scss/_custom.scss';
import { ResidentFormProvider } from "./views/resident-form/contexts/ResidentContext";

const ResidentForm = React.lazy(() => import('./views/resident-form/Resident'));

const App = () => {

  return (
    <div data-testid="app-root">
      <HashRouter>
        <Suspense
          fallback={
            <div className="pt-3 text-center">
              <CSpinner color="primary" variant="grow" />
            </div>
          }
        >
          <ResidentFormProvider>
            <Routes>
              <Route path="*" element={<Navigate to="signup" replace/>}/>
              <Route path="/signup" name="Resident" element={<ResidentForm />} />
            </Routes>
          </ResidentFormProvider>
        </Suspense>
      </HashRouter>
    </div>
  );
};

export default App;

import React, { createContext, useContext } from "react";
import { useResidentData } from "../hooks/useResidentData";

const ResidentContext = createContext();

export const useResident = () => useContext(ResidentContext);

export const ResidentFormProvider = ({ children }) => {
  const { createResident, allProperties } = useResidentData();

  return (
    <ResidentContext.Provider value={{ createResident, allProperties }}>
      {children}
    </ResidentContext.Provider>
  );
};
import axiosInstance from '../../../apis/axiosInstance';
import { residentServiceUrl, propertyServiceUrl } from "../../../utils/apiUrl";

// Create a new resident
export const createResidentService = async (residentData) => {
  const {
    email,
    name,
    phone,
    property,
    unit,
  } = residentData;

  const payload = {
    email,
    name,
    phone,
    property,
    unit,
    click_wrap_agreement: `<div><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p><p>It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>This is a dummy content for Terms and Conditions.</div>`
  };

  try {
    const response = await axiosInstance.post(residentServiceUrl, payload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchAllPropertiesService = async () => {
  try {
    const response = await axiosInstance.get(
      `${propertyServiceUrl}/names`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addToast } from "../../../store/toastSlice";
import { setLoading } from "../../../store/uiSlice";
import {
  createResidentService,
  fetchAllPropertiesService
} from "../services/residentServices";

export const useResidentData = () => {
  const [allProperties, setAllProperties] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading(true));
  }, []);

  useEffect(() => {
    fetchAllProperty();
  }, []);

  const handleError = (error) => {
    dispatch(
      addToast({
        text: error?.response?.data?.meta?.message || "Something went wrong",
        color: "danger",
      })
    );
  };

  const createResident = async (resident) => {
    try {
      const newResident = await createResidentService(resident);
      dispatch(addToast({ text: newResident.meta.message, color: "success" }));
      return newResident
    } catch (error) {
      handleError(error)
      return error
    }
  };

  const fetchAllProperty = async () => {
    try {
      const allProperties = await fetchAllPropertiesService();
      setAllProperties(allProperties.data);
    } catch (error) {
      handleError(error)
    }
  };

  return {
    createResident,
    allProperties
  };
};
